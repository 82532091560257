import {HttpService} from "./http.service";

export class ApiService extends HttpService{

    async  getArticles(tag:string,index:number): Promise<any> {
        console.log(tag)
        if(tag !=='foryou'){
        return this.get(`/news?tag=${tag}&lang=en&items=30&offset=${index}`);
        }
        else {
            return this.get(`/news?lang=en&items=30&offset=${index}`);
        }
    }
    async  getConfigs(): Promise<any> {
        console.log("configs called")
        return this.get(`/configs`,);
    }
}
