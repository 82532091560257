import PostItem from "./PostItem";
import {useEffect, useState} from "react";
import {ApiService} from "../../service/api.service";
import {ShimmerLayout} from "./ShimmerLayout";
import {useAppContext} from "../../context/AppContext";
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";

interface PostProps {
    tag: string
}


function PostsLayout(props:PostProps) {
    const apiService = new ApiService()
    const [articles,setArticles] = useState([])
    const [loading,setLoading] = useState(false)
    const {isWebOpen,setWebOpen } = useAppContext();
    const history = useNavigate();
    const [currentPage,setCurrentPage]=useState(0)

    const handleHover = (
        e: React.MouseEvent<HTMLElement, MouseEvent>,
        idx: number
    ) => {
        if (e.type === 'mouseenter') {
           // dispatch({ type: ActionType.SELECT, payload: idx });
        } else {
          //  dispatch({ type: ActionType.SELECT, payload: -1 });
        }
    };

    useEffect(() => {
       if(currentPage===0){
        setLoading(true)
       }
        apiService.getArticles(props.tag,currentPage*30).then((data)=>{
            if(currentPage===0){
                setArticles(data.data)
                setLoading(false)
            }
            else {
                setArticles(articles.concat(data.data))
            }

        })
    },[currentPage])


    return (
        <>{!loading?(<div
            id="scrollableDiv"
            className="flex flex-col overflow-y-scroll h-full w-full no-scrollbar"
            >
            <InfiniteScroll
                dataLength={articles.length}
                next={()=>{
                    setCurrentPage(currentPage+1)
                    console.log(currentPage)
                }}
                hasMore={true}
                scrollThreshold={0.8}
                loader={<div/>}
                scrollableTarget="scrollableDiv"
               >
                {articles.map((post: any, idx) => (
                    <div className='flex flex-row"'
                        key={idx.toString()}
                        onMouseEnter={(e) => handleHover(e, idx)}
                        onMouseLeave={(e) => handleHover(e, idx)}
                    >
                        <div onClick={() =>{
                            setWebOpen(true,post)
                            history(`/article`)

                        }
                        }>
                            <PostItem
                                article={post}
                                isOpen={isWebOpen}
                            />
                        </div>
                    </div>
                ))}

            </InfiniteScroll>
        </div>)
            :(<div><ShimmerLayout/></div>)
        }
        </>

    )
}

export default PostsLayout;
