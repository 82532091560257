import {useAppContext} from "../../context/AppContext";

export function ShimmerLayout() {
    const {isMobile } = useAppContext();
    return (
        <div>{!isMobile?
        (<>
            <ShimmerDeskTop/>
            <ShimmerDeskTop/>
            <ShimmerDeskTop/>
            <ShimmerDeskTop/>
            <ShimmerDeskTop/>
        </>):(<>
                <ShimmerMobile/>
                <ShimmerMobile/>
                <ShimmerMobile/>
                <ShimmerMobile/>
                <ShimmerMobile/>
            </>)
        }</div>
    )
}

 function ShimmerDeskTop() {
    return (
        <>
            <div
                className="animate-pulse container flex flex-col  w-full items-center justify-items-start space-y-2 ">
                <div className="h-2"/>
                <div className="self-start flex flex-column items-center space-x-2.5">
                    <div className="bg-slate-200 rounded-full h-6 w-6"/>

                    <div className="bg-slate-200 h-3 space-x-2.5 w-40">

                    </div>
                </div>
                <div className="flex flex-row w-full space-x-2.5">
                    <div className="flex flex-col w-10/12">
                        <div className="self-start bg-slate-200 h-4 w-5/6"></div>
                        <div className="self-start bg-slate-200 h-4 w-3/4 mt-4"></div>
                        <div className="flex flex-row w-full my-5 invisible">
                            <div
                                className="self-center self-start py-1.5 px-4 w-1/3 rounded h-4 bg-slate-200  align-baseline  leading-none">

                            </div>


                            <div className="self-end w-full flex flex-row justify-end space-x-3 invisible">
                                <div className="tm w-6 h-6 stroke-1 stroke-gray-500 bg-slate-200 rounded-full">

                                </div>
                                <div className="tm w-6 h-6 stroke-1 stroke-gray-500 bg-slate-200 rounded-full">
                                </div>
                                <div className="tm w-6 h-6 stroke-1 stroke-gray-500 bg-slate-200 rounded-full">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-2/12">
                        <div
                             className="mx-auto w-full bg-slate-200 h-full"/>
                    </div>


                </div>


            </div>

        </>
    )
}

function ShimmerMobile() {
    return (
        <>
            <div
                className="animate-pulse container flex flex-row w-full p-5">
                <div className='flex flex-col w-3/4 space-y-2 w-3/4'>
                <div className="self-start flex flex-column items-center space-x-2.5">
                    <div className="bg-slate-200 rounded-full h-6 w-6"/>

                    <div className="bg-slate-200 h-3  w-40">

                    </div>
                </div>
                <div className="flex flex-row w-full">
                    <div className="flex flex-col w-10/12">
                        <div className="self-start bg-slate-200 h-4 w-5/6"></div>
                        <div className="self-start bg-slate-200 h-4 w-3/4 mt-4"></div>
                        <div className="flex flex-row w-full my-5 invisible">
                            <div
                                className="self-center self-start py-1.5 px-4 w-1/3 rounded h-4 bg-slate-200  align-baseline  leading-none">

                            </div>


                            <div className="self-end w-full flex flex-row justify-end space-x-3 invisible">
                                <div className="tm w-6 h-6 stroke-1 stroke-gray-500 bg-slate-200 rounded-full">

                                </div>
                                <div className="tm w-6 h-6 stroke-1 stroke-gray-500 bg-slate-200 rounded-full">
                                </div>
                                <div className="tm w-6 h-6 stroke-1 stroke-gray-500 bg-slate-200 rounded-full">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                <div className="w-1/4 p-2">
                    <div
                        className="mx-auto w-full bg-slate-200 h-full w-full"/>
                </div>

            </div>

        </>
    )
}