import {
    Page,
    Navbar,
    Toolbar,
    Link,
    App,
} from 'konsta/react';
import {Home} from "../icons/Home";
import {BookMark} from "../icons/BookMark";
import {Search} from "../icons/Search";
import {Notification} from "../icons/Notification";
import {Outlet} from "react-router-dom";
import {MdOutlineArticle} from "react-icons/md";
import {AiOutlinePlayCircle} from "react-icons/ai";
import {CgStories} from "react-icons/cg";
import {SiGooglepodcasts} from "react-icons/si";

export function HomeMobile() {
    return (
        <App theme="material">
            <Page colors={{bgMaterial:'bg-white'}}>
                <Navbar
                    title="Home"
                    colors={
                        {bgMaterial:'bg-white'}
                    }
                    className={'ml-2 mt-4'}
                    titleClassName={'font-bold'}
                >

                    <div className={'w-full flex flex-row justify-end m-10'}>
                        <Link ><Notification/></Link>
                    </div>
                </Navbar>

                <Toolbar
                    colors={{bgMaterial:'bg-white'}}
                    className={`left-0 bottom-0 fixed w-full`}
                >
                    <Link toolbar><MdOutlineArticle size={24} color={'#A8A8A8'} strokeWidth={0.01}/></Link>
                    <Link toolbar> <AiOutlinePlayCircle size={24} color={'#A8A8A8'} strokeWidth={0.01}/></Link>
                    <Link toolbar><CgStories size={24} color={'#A8A8A8'} strokeWidth={0.01}/></Link>
                    <Link toolbar>  <SiGooglepodcasts size={24} color={'#A8A8A8'} strokeWidth={0.01}/></Link>
                    <Link toolbar>
                        <div className="flex-shrink-0 w-6 h-6  self-center">
                            <img src="https://miro.medium.com/fit/c/48/48/0*X8g09MXvSom-MP0-" alt="..."  loading="lazy"
                                 className="shadow rounded-full max-w-full h-auto align-middle border-none"/>

                        </div>
                    </Link>
                </Toolbar>

                <div className={'bg-white'}>
                    <Outlet/>
                </div>
            </Page>
        </App>
    )
}