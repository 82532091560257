
import {Link, useNavigate} from "react-router-dom";
import {
    HiOutlineLogout
} from "react-icons/hi";
import {Write} from "../icons/Write";
import {CgStories} from "react-icons/cg";
import {AiOutlinePlayCircle} from "react-icons/ai";
import {SiGooglepodcasts} from "react-icons/si";
import {MdOutlineArticle} from "react-icons/md";
import {BookMark} from "../icons/BookMark";
import {useAppContext} from "../context/AppContext";
import {cx} from "@vechaiui/react";
interface PostProps {
    tag?: string,
}

function SidebarLayout(props:PostProps) {
    const {selectedPage, setSelectedPage } = useAppContext();
    const history = useNavigate();
    return (
        <aside className="h-full fixed inset-y-0 z-10 flex flex-shrink-0 w-20  focus:outline-none">
            <nav className="flex flex-col flex-shrink-0 h-full  py-4 border-r dark:border-primary-darker w-full">
                <div className="flex-shrink-0 w-10 h-10 m-5 self-center">
                    <img src="./android-icon-192x192.png" alt="..."
                         className="shadow rounded-full max-w-full h-auto align-middle border-none"/>

                </div>
                <div className="flex flex-col items-center justify-center flex-1 space-y-10 w-full">
                    <div
                       className={
                           cx(
                               'p-2 transition-colors duration-200 text-primary-lighter bg-primary-50 hover:text-primary hover:bg-primary-100 dark:hover:text-light dark:hover:bg-primary-dark dark:bg-dark focus:outline-none focus:bg-primary-100 dark:focus:bg-primary-dark focus:ring-primary-darker w-full flex items-center justify-center',
                               selectedPage===0
                                   ? 'border-r-2 border-r-red-600'
                                   : ''
                           )
                       }>
                        <span className="sr-only">News</span>
                        <div onClick={ ()=>{
                            setSelectedPage(0)
                            history('/')
                        }}>
                            <MdOutlineArticle size={24} color={'#A8A8A8'} strokeWidth={0.01}/>
                        </div>
                    </div>
                    <div
                       className={
                           cx(
                               'p-2 transition-colors duration-200 text-primary-lighter bg-primary-50 hover:text-primary hover:bg-primary-100 dark:hover:text-light dark:hover:bg-primary-dark dark:bg-dark focus:outline-none focus:bg-primary-100 dark:focus:bg-primary-dark focus:ring-primary-darker w-full flex items-center justify-center',
                               selectedPage===1
                                   ? 'border-r-2 border-r-red-600'
                                   : ''
                           )
                       }>
                        <span className="sr-only">Videos</span>

                      <div onClick={ ()=>{
                                setSelectedPage(1)
                                history('/videos')
                            }}>
                        <AiOutlinePlayCircle size={24} color={'#A8A8A8'} strokeWidth={0.01}/>
                        </div>
                    </div>
                    <div
                       className={
                           cx(
                               'p-2 transition-colors duration-200 text-primary-lighter bg-primary-50 hover:text-primary hover:bg-primary-100 dark:hover:text-light dark:hover:bg-primary-dark dark:bg-dark focus:outline-none focus:bg-primary-100 dark:focus:bg-primary-dark focus:ring-primary-darker w-full flex items-center justify-center',
                               selectedPage===2
                                   ? 'border-r-2 border-r-red-600'
                                   : ''
                           )
                       }>
                        <span className="sr-only">Stories</span>
                        <div onClick={ ()=>{
                            setSelectedPage(2)
                            history('/stories')
                        }}>
                        <CgStories size={24} color={'#A8A8A8'} strokeWidth={0.01}/>
                        </div>
                    </div>
                    <div
                       className={
                           cx(
                               'p-2 transition-colors duration-200 text-primary-lighter bg-primary-50 hover:text-primary hover:bg-primary-100 dark:hover:text-light dark:hover:bg-primary-dark dark:bg-dark focus:outline-none focus:bg-primary-100 dark:focus:bg-primary-dark focus:ring-primary-darker w-full flex items-center justify-center',
                               selectedPage===3
                                   ? 'border-r-2 border-r-red-600'
                                   : ''
                           )
                       }>
                        <span className="sr-only">Podcasts</span>
                        <div onClick={ ()=>{
                            setSelectedPage(3)
                            history('/podcasts')
                        }}>
                        <SiGooglepodcasts size={24} color={'#A8A8A8'} strokeWidth={0.01}/>
                        </div>
                    </div>
                    <div className="w-full pl-5 pr-5">
                        <div className="border-b border-gray-300">
                            <div></div>
                        </div>
                    </div>

                    <div
                       className={
                           cx(
                               'p-2 transition-colors duration-200 text-primary-lighter bg-primary-50 hover:text-primary hover:bg-primary-100 dark:hover:text-light dark:hover:bg-primary-dark dark:bg-dark focus:outline-none focus:bg-primary-100 dark:focus:bg-primary-dark focus:ring-primary-darker w-full flex items-center justify-center',
                               selectedPage===4
                                   ? 'border-r-2 border-r-red-600'
                                   : ''
                           )
                       }>
                        <span className="sr-only">Favorites</span>
                        <div onClick={ ()=>{
                            setSelectedPage(4)
                            history('/favorites')
                        }}>
                        <BookMark/>
                        </div>
                    </div>

                </div>
                <div className="flex items-center justify-center flex-shrink-0">
                    <div className="flex-shrink-0 w-8 h-8  self-center">
                        <img src="https://miro.medium.com/fit/c/48/48/0*X8g09MXvSom-MP0-" alt="..."  loading="lazy"
                             className="shadow rounded-full max-w-full h-auto align-middle border-none"/>

                    </div>

                </div>


            </nav>
        </aside>
    )
}

export default SidebarLayout;