import {useEffect, useState} from "react";
import {HomeMobile} from "./HomeMobile";
import {HomeDeskTop} from "./HomeDeskTop";
import {WebViewArticle} from "../components/posts/WebViewArticle";

function Home() {

    const [isMobile, setIsMobile] = useState(false)
    const handleResize = () => {
        if (window.innerWidth < 720) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }
    useEffect(() => {
        if (window.innerWidth < 720) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
        window.addEventListener("resize", handleResize)
    })


    return (
        <div>
            {!isMobile ? (<div >
               <HomeDeskTop/>
            </div>): (
                <div>
                   <HomeMobile/>
                </div>
            )}
        </div>
    );
}


export default Home;