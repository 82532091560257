import { Tab } from "@headlessui/react";
import { cx } from "@vechaiui/react";
import PostsLayout from "./posts/PostsLayout";
import {IoIosAdd, IoIosArrowForward} from "react-icons/io";
import {useAppContext} from "../context/AppContext";
import {ShimmerLayout} from "./posts/ShimmerLayout";



function TabLayoutDeskTop(){
    const { selectedTab,selectedPage,onSelectedTab,newsTags} = useAppContext();
    return (
        <div className="h-full w-full  mt-3.5">
            <Tab.Group vertical as="div"  selectedIndex={selectedTab} defaultIndex={selectedTab}
                       className={cx(
                           "w-full"
                       )}
                       onChange={
                           (index:any)=>{
                               if (onSelectedTab) {
                                   onSelectedTab(index)
                               }
                           }
                       }
            >

                <Tab.List as = "div"
                          aria-label="tabs example"
                          className={cx(
                              "border-b border-neutral-200 dark:border-neutral-700 bg-white","flex flex-nowrap   no-scrollbar overflow-x-scroll","ml-28","mr-28"
                          )}
                >
                    <Tab
                        key="add"
                        className={cx(
                            'px-4 h-10 py-2 -mb-px text-sm text-center whitespace-nowrap cursor-base"',
                            'text-gray-500 bg-transparent','border-transparent focus:outline-none'
                        )}
                    >
                        <IoIosAdd size={24}/>
                    </Tab>

                    {newsTags.map((tab:any) => (
                        <Tab
                            key={tab.tag}
                            className={({ selected }) =>
                                cx(
                                    'px-4 h-10 py-2 -mb-px text-sm text-center whitespace-nowrap cursor-base text-gray-500 bg-transparent',
                                    selected
                                        ? 'border-b-2 border-black text-black focus:outline-none'
                                        : 'border-transparent focus:outline-none'
                                )
                            }
                        >
                            {tab.tagName}
                        </Tab>
                    ))}
                    <Tab
                        key="nav"
                        className={cx(
                            'px-4 h-10 py-2 -mb-px text-sm text-center whitespace-nowrap cursor-base"',
                            'text-gray-500 bg-transparent','border-transparent focus:outline-none'
                        )}
                    >
                        <IoIosArrowForward size={24}/>
                    </Tab>
                </Tab.List>

                <Tab.Panels>
                    <Tab.Panel

                        className="fixed h-full w-8/12  overflow-y-scroll pr-28 pl-28"
                    ></Tab.Panel>
                    {
                        newsTags.map((tab:any) =>{
                         return (
                                <Tab.Panel
                                    key={tab.tag}
                                    className="fixed h-full w-8/12  overflow-y-scroll pr-28 pl-28"
                                >

                                    <PostsLayout tag={tab.tag}/>
                                </Tab.Panel>
                            )

                    })
                    }
                </Tab.Panels>
            </Tab.Group>
        </div>
    )
}
function TabLayoutMobile(){
    const { selectedTab, onSelectedTab,newsTags} = useAppContext();
    return (
        <div className="h-full w-full bg-red-100 mr-2 ml-2">
            <Tab.Group
                vertical as="div"
                selectedIndex={selectedTab}
                onChange={
                    (index:any)=>{
                        if (onSelectedTab) {
                            onSelectedTab(index)
                        }
                    }
                }
                defaultIndex={selectedTab}
                className={cx(
                           "w-full"
                       )}

            >

                <Tab.List as = "div"
                          className={cx(
                              "bg-white","flex flex-nowrap   no-scrollbar overflow-x-scroll"
                          )}
                >
                    <Tab
                        key="add"
                        className={cx(
                            'py-0.5 px-4 mx-1 text-xs rounded-2xl inline-block whitespace-nowrap text-center bg-gray-100  hover:text-black',
                            ' focus:text-white focus:bg-black','text-gray-500 focus:outline-none'
                        )
                        }
                    >
                        <IoIosAdd size={24}/>
                    </Tab>
                    {newsTags.map((tab:any) => (
                        <Tab
                            key={tab}
                            value={tab}
                            className={({ selected }) =>
                                cx(
                                    'py-0.5 px-4 mx-1 text-xs rounded-2xl inline-block whitespace-nowrap text-center bg-gray-100  hover:text-black focus:text-white focus:bg-black',
                                    selected
                                        ? 'text-white bg-black focus:outline-none'
                                        : 'text-gray-500 focus:outline-none'
                                )
                            }
                        >
                            {
                               tab.tagName
                            }
                        </Tab>
                    ))}
                    <Tab
                        key="add"
                        className={cx(
                            'py-0.5 px-4 mx-1 text-xs rounded-2xl inline-block whitespace-nowrap text-center bg-gray-100  hover:text-black',
                            ' focus:text-white focus:bg-black','text-gray-500 focus:outline-none'
                        )
                        }
                    >
                        <IoIosArrowForward size={24}/>
                    </Tab>
                </Tab.List>

                <Tab.Panels>
                    <Tab.Panel
                        key="add"
                        className="fixed h-full w-full  overflow-y-scroll"
                    >
                    </Tab.Panel>
                    {newsTags.map((tab:any) => (
                        <Tab.Panel
                            key={tab.tag}
                            className="fixed h-full w-full  overflow-y-scroll"
                        >
                            <PostsLayout  tag={tab.tag}/>
                        </Tab.Panel>
                    ))}
                </Tab.Panels>
            </Tab.Group>
        </div>
    )
}


function TabLayout() {
    const {isMobile , newsTags} = useAppContext();
    return (
        <>{ newsTags.length>0 ?
     ( <div>
            {!isMobile ? (<div >
                <TabLayoutDeskTop/>
            </div>): (
                <div>
                    <TabLayoutMobile/>
                </div>
            )}
        </div>):(<ShimmerLayout/>)
        }
        </>
    )
  }

  export default TabLayout;