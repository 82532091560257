export function ArticleShimmerLayout() {
    return (
        <>
            <div
                className="w-full h-full"
            >
                <div
                    className="animate-pulse container flex flex-row w-full h-full p-5">
                    <div className='flex flex-col w-full h-full space-y-3 items-center'>
                        <div className="flex flex-column items-center w-3/5 space-x-2.5">
                            <div className="bg-slate-200 rounded-full h-6 w-6"/>
                            <div className="bg-slate-200 h-3  w-40"/>
                        </div>
                        <div className='bg-slate-200 w-3/5 h-1/3 rounded'/>
                        <div className="bg-slate-200 h-4 w-3/5"/>
                        <div className="bg-slate-200 h-4 w-3/5 mt-4"/>
                        <div className="flex flex-row h-50 w-3/5 items-center">
                            <div className='bg-slate-200 w-40  h-20 rounded'/>
                            <div className='ml-5 flex flex-col w-full'>
                                <div className="bg-slate-200 h-4  w-3/5"/>
                                <div className="bg-slate-200 h-4 mt-4 w-3/5"/>
                            </div>

                        </div>
                        <div className="bg-slate-200 h-4  w-3/5"/>
                        <div className="bg-slate-200 h-4 mt-4 w-3/5"/>
                        <div className="flex flex-row h-50 w-3/5 items-center">
                            <div className='bg-slate-200 w-40  h-20 rounded'/>
                            <div className='ml-5 flex flex-col w-full'>
                                <div className="bg-slate-200 h-4  w-3/5"/>
                                <div className="bg-slate-200 h-4 mt-4 w-3/5"/>
                            </div>

                        </div>
                        <div className="bg-slate-200 h-4  w-3/5"/>
                        <div className=" bg-slate-200 h-4 mt-4 w-3/5"/>
                        <div className="flex flex-row h-50 w-3/5 items-center">
                            <div className='bg-slate-200 w-40  h-20 rounded'/>
                            <div className='ml-5 flex flex-col w-full'>
                                <div className="bg-slate-200 h-4  w-3/5"/>
                                <div className="bg-slate-200 h-4 mt-4 w-3/5"/>
                            </div>

                        </div>
                        <div className="bg-slate-200 h-4  w-3/5"/>
                        <div className=" bg-slate-200 h-4 mt-4 w-3/5"/>


                    </div>
                </div>

            </div>
        </>
    )
}