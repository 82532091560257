import React, {FC, FunctionComponent, useEffect, useState} from 'react';
import {ApiService} from "../service/api.service";
import {Article} from "../utils/types";


type AppPrefs = {
   selectedTab:number,
   isMobile:boolean,
   newsTags:any,
   onSelectedTab? : (index:number)=>void;
   setWebOpen : (index:boolean,article:Article)=>void;
   setSelectedPage : (index:number)=>void;
   isWebOpen:boolean,
   article:Article,
   selectedPage:number

};

const defaultViewerContext: AppPrefs = {
   selectedTab:1,
   isMobile:false,
   newsTags:[],
   setWebOpen : (index:boolean,article:Article)=>{},
   setSelectedPage : (index:number)=>{},
   isWebOpen:false,
   article:{
      amp: "",
      articleId: 0,
      author: "",
      datetime: "",
      icon: "",
      lang: "",
      link: "",
      tag: "",
      thumbnail: "",
      title: "",
      sourcetitle:''
   },
   selectedPage:0
}

export const AppContext = React.createContext<AppPrefs>(defaultViewerContext);

export function useAppContext() {
   return React.useContext(AppContext);
}

export const AppContextProvider: FunctionComponent<any> = ({ children }) => {
   const [isMobile, setIsMobile] = useState(defaultViewerContext.isMobile)
   const [selectedTab, setSelectedTab] = useState(defaultViewerContext.selectedTab)
   const [newsTags, setNewsTags] = useState(defaultViewerContext.newsTags)
   const [isWebOpen, setWebOpen] = useState(false);
   const [article, setAricle] = useState<Article>(defaultViewerContext.article);
   const [ selectedPage, setSelectedPage] = useState(defaultViewerContext.selectedPage);

   const apiService = new ApiService()
   const handleResize = () => {
      if (window.innerWidth < 720) {
         setIsMobile(true)
      } else {
         setIsMobile(false)
      }
   }
   const onSelectedTab = (index:number) => {
      setSelectedTab(index);
   };
   const onSelectedPage = (index:number) => {
      setSelectedPage(index);
   };
   const onsetWebOpen = (index:boolean,article:Article) => {
      setAricle(article)
      setWebOpen(index);
   };
   useEffect(() => {
      if (window.innerWidth < 720) {
         setIsMobile(true)
      } else {
         setIsMobile(false)
      }
      window.addEventListener("resize", handleResize)
      apiService.getConfigs().then((data)=>{
         setNewsTags(data.data.news)
      })
   },[])
   return (
       <AppContext.Provider value={{selectedTab:selectedTab,onSelectedTab:onSelectedTab,isMobile:isMobile,newsTags:newsTags,isWebOpen:isWebOpen,setWebOpen:onsetWebOpen,article:article,selectedPage:selectedPage,setSelectedPage:onSelectedPage}}>
          {children}
       </AppContext.Provider>
   );
};



