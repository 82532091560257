import {useAppContext} from "../../context/AppContext";
import {Article} from "../../utils/types";
import SearchLayout from "../SearchLayout";
import {RiMailAddLine} from "react-icons/ri";
import posts from "../../pages/sample";
import {Link} from "react-router-dom";
import {AiOutlineClose} from "react-icons/ai";
import {useState} from "react";
import {ArticleShimmerLayout} from "./ArticleShimmerLayout";


export function WebViewArticle() {
    const {setWebOpen, article} = useAppContext();
    const followed = false;
    const [loading,setLoading]=useState(true)
    console.log(article.amp ? article.amp : article.link)
    return (
        <>
            <div className='fixed inset-0 z-40 bg-white w-screen flex flex-row'>
                <div
                    onClick={() => setWebOpen(false, article)}
                    className="w-2/3 h-screen"
                >
                    {loading ? (
                        <div
                            className="w-full h-full"
                        >
                        <ArticleShimmerLayout/>
                        </div>
                    ) : null}
                    <iframe title={article.title} className="w-full h-full pl-10 pr-10 pt-5"
                            src={getAmpCdnUrl(article)} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            onLoad={()=>{
                             setLoading(false)
                             console.log("iframe loaded")
                            }}
                    ></iframe>


                </div>
                <div className="w-1/3 h-screen flex flex-col">
                 <div
                        className="p-8 flex flex-col fixed  bottom-0 top-0 overflow-y-scroll w-1/3 no-scrollbar justify-items-center">
                     <div className="w-full py-5 relative">
                         <AiOutlineClose size={24}  className="absolute right-4" onClick={()=>{
                             window.history.back()
                         }} />
                     </div>
                        <SearchLayout/>
                        <>
                            <div className="w-full">
                                <div>
                                    <img
                                        className="h-10 w-10 object-cover rounded-full shadow"
                                        src={'https://cdn.newspost.today/siteicons/' + article.icon}
                                        alt=""
                                    />
                                    <h1 className="font-bold py-4 text-gray-900">
                                        {article.sourcetitle}
                                    </h1>
                                    <h2 className="text-gray-500 text-sm">
                                        {article.author}
                                    </h2>
                                    <div className="py-4 flex items-center space-x-4">
                                        <button
                                            value={followed ? "Followed" : "Follow"}
                                            className={`${
                                                followed
                                                    ? "border-[1px] rounded-full border-green-600 hover:border-green-800 duration-500  px-6 py-2 text-gray-900"
                                                    : "bg-green-600 rounded-full hover:bg-green-700 duration-500  px-4 py-2 text-white"
                                            }`}
                                        >
                                            {followed ? "Following" : "Follow"}
                                        </button>
                                        <div
                                            className="h-[37px] w-[37px] cursor-pointer flex justify-center items-center  rounded-full bg-green-600 hover:bg-green-700 duration-500"
                                        >
                                            <RiMailAddLine className="text-white text-xl"/>{" "}
                                        </div>
                                    </div>
                                    <div className="py-4">
                                        <h2 className="text-gray-900 text-md font-semibold capitalize">
                                            more from {article.sourcetitle}
                                        </h2>
                                        <div className="py-6">
                                            {posts.slice(0, 5)?.map((post: any, index) => (
                                                <Link
                                                    key={index.toString() + "related"}
                                                    to="article">
                                                    <div className="flex items-start justify-between py-4">
                                                        <div
                                                            className="space-y-2 flex flex-col jusify-center cursor-pointer">
                                                            <div className="flex items-center space-x-2">
                                                                {" "}
                                                                <img
                                                                    alt="r"
                                                                    className="h-6 rounded-full"
                                                                    src={post.authorimage}
                                                                />
                                                                <span className="text-sm font-normal capitalize">
                              {post.author}
                            </span>
                                                            </div>
                                                            <div>
                                                                <p className="text-[16px] text-md font-semibold w-[225px]">
                                                                    {post.title}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="h-16 w-16 flex justify-start">
                                                            <img
                                                                className="h-full w-full rounded-md cursor-pointer  object-cover group-hover:scale-105 transition-transform duration-200 ease-in-out"
                                                                src={post.image}
                                                                alt="image"
                                                            />
                                                        </div>
                                                    </div>
                                                </Link>
                                            ))}
                                        </div>
                                        <div className="text-xs mt-10">
                  <span className="cursor-pointer text-gray-500 hover:text-gray-900 duration-100 pr-4">
                    Help
                  </span>

                                            <span
                                                className="cursor-pointer text-gray-500 hover:text-gray-900 duration-100 pr-4">
                    Status
                  </span>

                                            <span
                                                className="cursor-pointer text-gray-500 hover:text-gray-900 duration-100 pr-4">
                    Writers
                  </span>

                                            <span
                                                className="cursor-pointer text-gray-500 hover:text-gray-900 duration-100 pr-4">
                    Blog
                  </span>

                                            <span
                                                className="cursor-pointer text-gray-500 hover:text-gray-900 duration-100 pr-4">
                    Careers
                  </span>

                                            <span
                                                className="cursor-pointer text-gray-500 hover:text-gray-900 duration-100 pr-4">
                    Privacy
                  </span>
                                            <br/>
                                            <span
                                                className="cursor-pointer text-gray-500 hover:text-gray-900 duration-100 pr-4">
                    Terms
                  </span>

                                            <span
                                                className="cursor-pointer text-gray-500 hover:text-gray-900 duration-100 pr-4">
                    About
                  </span>
                                            <span
                                                className="cursor-pointer text-gray-500 hover:text-gray-900 duration-100 pr-4">
                    Knowable
                  </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    </div>
                </div>
            </div>
        </>
    )
}

function getAmpCdnUrl(article: Article): string {
    if (!article.amp) {
        return article.link
    } else {
        const url = new URL(article.amp);
        return "https://" + url.host.replaceAll(".", "-") + ".cdn.ampproject.org/c/s/" + article.amp.replace("https://", "").replace("http://", "");
    }

}