

import {ArticleProps} from "../../utils/types";
import {useAppContext} from "../../context/AppContext";


function PostItemDesktop(props: { src: string, author: string, time: string, title: string, description: string, tag: string, src1: string }) {
    return (
        <div
            className="container flex flex-col  w-full items-center justify-items-start space-y-2 border-b border-grey-600">
            <div className="h-2"/>
            <div className="self-start flex flex-column items-center space-x-2.5">
                <img alt="profile" src={props.src}
                     className="mx-auto object-cover rounded-full h-5 w-5"/>

                <div className="font-medium dark:text-white">
                    {props.author}
                </div>
                <div className="text-gray-600 dark:text-gray-200 text-xs center space-x-2.5">
                    {props.time}
                </div>
            </div>
            <div className="flex flex-row w-full space-x-2.5">
                <div className="flex flex-col w-10/12">
                    <p className="self-start text-2xl font-semibold">{props.title}</p>
                    <p className="self-start font-normal line-clamp-3 text-gray-600 font-light">{props.description}</p>
                    <div className="flex flex-row w-full my-5">
                        <div
                            className="self-start py-1.5 px-4 text-xs rounded-2xl inline-block whitespace-nowrap text-center bg-gray-100 text-slate-650 align-baseline font-light  leading-none">
                            {props.tag}
                        </div>


                        <div className="self-end w-full flex flex-row justify-end space-x-3">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path
                                    d="M17.5 1.25a.5.5 0 0 1 1 0v2.5H21a.5.5 0 0 1 0 1h-2.5v2.5a.5.5 0 0 1-1 0v-2.5H15a.5.5 0 0 1 0-1h2.5v-2.5zm-11 4.5a1 1 0 0 1 1-1H11a.5.5 0 0 0 0-1H7.5a2 2 0 0 0-2 2v14a.5.5 0 0 0 .8.4l5.7-4.4 5.7 4.4a.5.5 0 0 0 .8-.4v-8.5a.5.5 0 0 0-1 0v7.48l-5.2-4a.5.5 0 0 0-.6 0l-5.2 4V5.75z"
                                    fill="#000"></path>
                            </svg>
                            <svg className="tm w-6 h-6 stroke-1 stroke-gray-500" width="24" height="24"
                                 viewBox="0 0 24 24" fill="none">
                                <path d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18zM8.25 12h7.5" stroke="currentColor"
                                      strokeLinecap="round" strokeLinejoin="round"></path>
                            </svg>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M4.39 12c0 .55.2 1.02.59 1.41.39.4.86.59 1.4.59.56 0 1.03-.2 1.42-.59.4-.39.59-.86.59-1.41 0-.55-.2-1.02-.6-1.41A1.93 1.93 0 0 0 6.4 10c-.55 0-1.02.2-1.41.59-.4.39-.6.86-.6 1.41zM10 12c0 .55.2 1.02.58 1.41.4.4.87.59 1.42.59.54 0 1.02-.2 1.4-.59.4-.39.6-.86.6-1.41 0-.55-.2-1.02-.6-1.41a1.93 1.93 0 0 0-1.4-.59c-.55 0-1.04.2-1.42.59-.4.39-.58.86-.58 1.41zm5.6 0c0 .55.2 1.02.57 1.41.4.4.88.59 1.43.59.57 0 1.04-.2 1.43-.59.39-.39.57-.86.57-1.41 0-.55-.2-1.02-.57-1.41A1.93 1.93 0 0 0 17.6 10c-.55 0-1.04.2-1.43.59-.38.39-.57.86-.57 1.41z"
                                      fill="#000"></path>
                            </svg>
                        </div>
                    </div>
                </div>
                <div className="w-2/12 mb-5">
                    <img alt="profile" src={props.src1}
                         className="mx-auto w-full object-cover object-center h-full"/>
                </div>


            </div>


        </div>);
}
function PostItemMobile(props: { src: string, author: string, time: string, title: string, description: string, tag: string, src1: string }) {
    return(
        <div
            className="container flex flex-col  w-full items-center justify-items-start space-y-2 border-b border-grey-600">
            <div className="h-2"/>

            <div className="flex flex-row w-full">
                <div className="flex flex-col w-3/4 ">
                    <div className="self-start flex flex-column items-center">
                        <img alt="profile" src={props.src}
                             className="mx-auto object-cover rounded-full h-5 w-5"/>

                        <div className="font-medium dark:text-white">
                            {props.author}
                        </div>
                        <div className="text-gray-600 dark:text-gray-200 text-xs center space-x-2.5">
                            {props.time}
                        </div>
                    </div>
                    <div className={'self-start'}>
                        <p className=" text-xl line-clamp-3 font-semibold">{props.title}</p>
                    </div>


                    <div className="flex flex-row w-full my-5">
                        <div
                            className="self-start py-1.5 px-4 text-xs rounded-2xl inline-block whitespace-nowrap text-center bg-gray-100 text-slate-650 align-baseline font-light  leading-none">
                            {props.tag}
                        </div>


                        <div className="self-end w-full flex flex-row justify-end space-x-3">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path
                                    d="M17.5 1.25a.5.5 0 0 1 1 0v2.5H21a.5.5 0 0 1 0 1h-2.5v2.5a.5.5 0 0 1-1 0v-2.5H15a.5.5 0 0 1 0-1h2.5v-2.5zm-11 4.5a1 1 0 0 1 1-1H11a.5.5 0 0 0 0-1H7.5a2 2 0 0 0-2 2v14a.5.5 0 0 0 .8.4l5.7-4.4 5.7 4.4a.5.5 0 0 0 .8-.4v-8.5a.5.5 0 0 0-1 0v7.48l-5.2-4a.5.5 0 0 0-.6 0l-5.2 4V5.75z"
                                    fill="#000"></path>
                            </svg>
                            <svg className="tm w-6 h-6 stroke-1 stroke-gray-500" width="24" height="24"
                                 viewBox="0 0 24 24" fill="none">
                                <path d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18zM8.25 12h7.5" stroke="currentColor"
                                      strokeLinecap="round" strokeLinejoin="round"></path>
                            </svg>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M4.39 12c0 .55.2 1.02.59 1.41.39.4.86.59 1.4.59.56 0 1.03-.2 1.42-.59.4-.39.59-.86.59-1.41 0-.55-.2-1.02-.6-1.41A1.93 1.93 0 0 0 6.4 10c-.55 0-1.02.2-1.41.59-.4.39-.6.86-.6 1.41zM10 12c0 .55.2 1.02.58 1.41.4.4.87.59 1.42.59.54 0 1.02-.2 1.4-.59.4-.39.6-.86.6-1.41 0-.55-.2-1.02-.6-1.41a1.93 1.93 0 0 0-1.4-.59c-.55 0-1.04.2-1.42.59-.4.39-.58.86-.58 1.41zm5.6 0c0 .55.2 1.02.57 1.41.4.4.88.59 1.43.59.57 0 1.04-.2 1.43-.59.39-.39.57-.86.57-1.41 0-.55-.2-1.02-.57-1.41A1.93 1.93 0 0 0 17.6 10c-.55 0-1.04.2-1.43.59-.38.39-.57.86-.57 1.41z"
                                      fill="#000"></path>
                            </svg>
                        </div>
                    </div>
                </div>
                <div className="w-1/4 items-center  flex align-middle">
                    <img alt="profile" src={props.src1}
                         className="mx-auto w-3/4 object-cover object-center" />
                </div>
            </div>


        </div>);

}

const PostItemComp: React.FC<ArticleProps> = ({ article}) => {
    const {isMobile } = useAppContext();
    return (

        (<div className={'ml-4 mr-4'}>
            {!isMobile?( <PostItemDesktop src={'https://cdn.newspost.today/siteicons/'+article.icon} author={article.author} time={article.datetime} title={article.title}
                                          description={""} tag={article.tag} src1={article.thumbnail}/>):( <PostItemMobile src={'https://cdn.newspost.today/siteicons/'+article.icon} author={article.author} time={article.datetime} title={article.title}
                                                                                                                                         description={""} tag={article.tag} src1={article.thumbnail}/>)}
        </div>)

    );
};

export default PostItemComp;