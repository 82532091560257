import axios, { AxiosRequestConfig } from 'axios';

const instance = axios.create({
	baseURL: "https://api.newspost.today",
});

instance.defaults.baseURL = "https://api.newspost.today";
instance.defaults.withCredentials = false;
instance.interceptors.request.use((config: any): AxiosRequestConfig => {
	return config;
});
export default instance;
